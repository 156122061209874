.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.fileContainer {
  width: 100%;
  height: 100%;
  max-height: 600px;
  overflow: auto;
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.link {
  text-decoration: none;
  color: #212529;
}
.listCategory {
  text-align: left;
}
.table {
  text-align: left;
}
.tableLink {
  color: #0d6efd;
  text-decoration: none;
}
.breadCrumbLink {
  text-decoration: none;
}
.input-left{
  text-align: left;
  float: left;
}
